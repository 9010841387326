import { Component, Input, OnInit } from "@angular/core";
import { Table } from "../../s25-table/Table";
import { S25CheckboxComponent } from "../../s25-checkbox/s25.checkbox.component";
import { Bind } from "../../../decorators/bind.decorator";
import { RegistrationService } from "../../../services/registration.service";
import { ContactService } from "../../../services/contact.service";

// Should be a CONST enum, but since we have to add the enum to the component to use it in the template it cannot be CONST
enum RegistrationStatus {
    None,
    Ongoing,
    Complete,
    Failed,
}

@Component({
    template: `
        @if (isInit) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body">
                    <div class="eventRegistrationModal">
                        @if (
                            registration.status === RegistrationStatus.None ||
                            registration.status === RegistrationStatus.Ongoing
                        ) {
                            <div>
                                <p>What dates would you like to register for?</p>
                                <div class="c-padding-top--single">
                                    <s25-toggle-button
                                        [(modelValue)]="isSelectIndividual"
                                        [falseLabel]="'All'"
                                        [trueLabel]="'Select Individual'"
                                    ></s25-toggle-button>
                                </div>
                                @if (isSelectIndividual) {
                                    <div>
                                        <s25-ng-table
                                            [dataSource]="tableConfig"
                                            [hasFilter]="true"
                                            [hasRefresh]="true"
                                        ></s25-ng-table>
                                    </div>
                                }
                                <button
                                    class="aw-button aw-button--primary c-margin-top--single ngFloatRight"
                                    [disabled]="isSelectIndividual && !selected.size"
                                    (click)="register()"
                                    (keydown.enter)="register()"
                                >
                                    Register
                                </button>
                            </div>
                        }
                        @if (registration.status === RegistrationStatus.Complete) {
                            <div class="ngCenterAlignText">
                                @if (!registration.fail.length) {
                                    <p class="c-margin-top--double">You have successfully registered for the event!</p>
                                }
                                @if (registration.fail.length) {
                                    <p class="c-margin-top--double">
                                        You have been successfully registered for these occurrences:
                                    </p>
                                    @for (date of registration.success; track date) {
                                        <div class="ngCenterAlignText">
                                            <div>{{ date }}</div>
                                        </div>
                                    }
                                    <p class="c-margin-top--double ngRed">
                                        You could not be registered for these occurrences:
                                    </p>
                                    @for (date of registration.fail; track date) {
                                        <div class="ngCenterAlignText">
                                            <div>{{ date }}</div>
                                        </div>
                                    }
                                }
                            </div>
                        }
                        @if (registration.status === RegistrationStatus.Failed) {
                            <div class="ngCenterAlignText">
                                <p class="c-margin-top--double">
                                    Sorry, we were not able to register you for this event.
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    `,
})
export class ModalRegisterEventComponent implements OnInit {
    RegistrationStatus = RegistrationStatus;

    @Input() data: {
        itemId: number;
        contId?: number;
        canEditStatus?: boolean;
        isRegistered?: boolean;
        title: string;
    };

    isInit = false;
    isSelectIndividual = false;
    selected = new Set<number>(); // Reservation IDs
    reservations: Record<number, string> = {}; // Map from reservation ID to date string
    registration: {
        status: RegistrationStatus;
        success: string[];
        fail: string[];
    } = { status: RegistrationStatus.None, success: [], fail: [] };
    tableConfig: Table.DataSource;

    ngOnInit() {
        this.tableConfig = {
            type: "unpaginated",
            columns: [
                { id: "date", header: "Date" },
                { id: "state", header: "State" },
                { id: "availableAttendance", header: "Available Attendance" },
                {
                    id: "include",
                    header: "Include",
                    content: {
                        component: S25CheckboxComponent,
                        outputs: {
                            modelValueChange: (include: boolean, row: Table.Row) =>
                                this.setSelected(row.id as number, include),
                        },
                    },
                },
                { id: "registrationStatus", header: "Registration Status" },
            ],
            dataSource: this.getRegistration,
        };

        this.isInit = true;
    }

    @Bind
    async getRegistration() {
        const data = await RegistrationService.getRegistrationWithOccurrences(this.data.itemId, [this.data.contId]);
        for (let { dateTime, rsrvId } of data.occurrences) this.reservations[rsrvId] = dateTime; // Cache date strings for later
        data.occurrences.sort((a, b) => (a.rsrvStartDt < b.rsrvStartDt ? -1 : 1));
        const rows = data.occurrences.map((occurrence) => ({
            id: occurrence.rsrvId,
            name: occurrence.dateTime,
            cells: {
                date: { text: occurrence.dateTime },
                state: { text: occurrence.stateName },
                availableAttendance: { text: occurrence.availableAttendance },
                registrationStatus: { text: occurrence.registrants?.[this.data.contId]?.txt },
                include: {
                    inputs: {
                        modelValue: false,
                        disabled: this.data.contId in occurrence.registrants || !occurrence.availableAttendance,
                    },
                },
            },
        }));

        return {
            rows: rows,
            totalRows: rows.length,
        };
    }

    setSelected(id: number, selected: boolean) {
        if (selected) this.selected.add(id);
        else this.selected.delete(id);
    }

    async register() {
        this.registration.status = RegistrationStatus.Ongoing;
        if (!this.data.contId) this.data.contId = await ContactService.getCurrentId();

        RegistrationService.putRegistration(this.data.itemId, 2, this.data.contId, Array.from(this.selected))
            .then((response) => {
                const failed = new Set<number>(); // Failed reservation IDs
                const success = new Set<number>(); // Registered reservation IDs
                for (let error of response.errors || []) failed.add(parseInt(error.message.match(/rsrvId: (\d+)/)[1]));
                for (let reservation of response.data.items[0].registration[0]?.reservations || [])
                    success.add(reservation.rsrvId);
                this.registration.status = RegistrationStatus.Complete;
                this.registration.fail = Array.from(failed, (id) => this.reservations[id]);
                this.registration.success = Array.from(success, (id) => this.reservations[id]);
            })
            .catch(() => (this.registration.status = RegistrationStatus.Failed));
    }
}
