import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { ModalInterface } from "../modal.interface";

@Component({
    template: `
        <s25-modal-header [data]="data"></s25-modal-header>
        <div class="modal-body ngCenter">
            <div class="ngCenter ngS25ModalDialogMsg" [innerHTML]="data.message | safeHTML"></div>
            <div class="ngCenter ngS25ModalDialogBtn">
                <button (click)="data?.closeModal?.()" class="aw-button aw-button--outline">OK</button>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAlertComponent implements ModalInterface {
    @Input() data: any;
}
