//@author: devin
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalInterface } from "../modal.interface";

@Component({
    template: `
        @if (initModal) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body ngCenter">
                    <div class="ngCenter ngS25ModalDialogMsg" [innerHTML]="data.message | safeHTML"></div>
                </div>
                <div class="modal-footer">
                    <div class="ngCenter ngS25ModalDialogBtn">
                        @if (data.buttonMap.hasYes) {
                            <div class="ngInlineBlock">
                                <button
                                    (click)="yes()"
                                    class="aw-button aw-button--outline"
                                    [class.aw-button--primary]="data.buttonMap.primary == 'Yes'"
                                >
                                    Yes
                                </button>
                            </div>
                        }
                        @if (data.buttonMap.hasContinue) {
                            <div class="ngInlineBlock">
                                <button
                                    (click)="continue()"
                                    class="aw-button aw-button--outline"
                                    [class.aw-button--primary]="data.buttonMap.primary == 'Continue'"
                                >
                                    Continue
                                </button>
                            </div>
                        }
                        @if (data.buttonMap.hasNo) {
                            <div class="ngInlineBlock">
                                <button
                                    (click)="no()"
                                    class="aw-button aw-button--outline"
                                    [class.aw-button--primary]="data.buttonMap.primary == 'No'"
                                >
                                    No
                                </button>
                            </div>
                        }
                        @if (data.buttonMap.hasCancel) {
                            <div class="ngInlineBlock">
                                <button
                                    (click)="cancel()"
                                    class="aw-button aw-button--outline"
                                    [class.aw-button--primary]="data.buttonMap.primary == 'Cancel'"
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                        @if (data.buttonMap.hasOK) {
                            <div class="ngInlineBlock">
                                <button
                                    (click)="close()"
                                    class="aw-button aw-button--outline"
                                    [class.aw-button--primary]="data.buttonMap.primary == 'OK'"
                                >
                                    OK
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    `,
    styles: `
        .ngS25ModalDialogBtn {
            display: flex;
            gap: 0.5em;
            padding-top: 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogComponent implements OnInit, ModalInterface {
    @Input() data: any;
    initModal: boolean = false;

    close() {
        this.data.closeModal();
        return this.data.answer;
    }

    continue() {
        this.data.answer = 1;
        return this.close();
    }

    yes() {
        this.data.answer = 1;
        return this.close();
    }

    no() {
        this.data.answer = 0;
        return this.close();
    }

    cancel() {
        this.data.answer = -1;
        return this.close();
    }

    ngOnInit() {
        this.initModal = true;
    }
}
